exports.components = {
  "component---src-pages-aktuality-js": () => import("./../../../src/pages/aktuality.js" /* webpackChunkName: "component---src-pages-aktuality-js" */),
  "component---src-pages-historie-js": () => import("./../../../src/pages/historie.js" /* webpackChunkName: "component---src-pages-historie-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-onas-js": () => import("./../../../src/pages/onas.js" /* webpackChunkName: "component---src-pages-onas-js" */),
  "component---src-pages-ospolecnosti-js": () => import("./../../../src/pages/ospolecnosti.js" /* webpackChunkName: "component---src-pages-ospolecnosti-js" */),
  "component---src-pages-produkty-js": () => import("./../../../src/pages/produkty.js" /* webpackChunkName: "component---src-pages-produkty-js" */)
}

